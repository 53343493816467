//
// _home.scss
//

/*********************************/
/*            Home               */
/*===============================*/
@mixin home-common {
  background-size: cover !important;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-home {
  height: 100vh;
  @include home-common();
  @media (max-width: 767px) {
    padding: 150px 0 !important;
    //height: auto;
  }
}

.bg-home-one {
  padding: 160px 0 220px;
  @include home-common();
}

.bg-half-260 {
  padding: 260px 0;
  @include home-common();
  @media (max-width: 768px) {
    padding: 150px 0;
    height: auto;
  }
}
.bg-half-170 {
  padding: 170px 0;
  @include home-common();
  @media (max-width: 767px) {
    padding: 120px 0;
    height: auto;
  }
}

.bg-building-pic {
  padding: 130px 0 0;
  @include home-common();
}
.slide-outer {
  position: relative;
}

.background-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

/*********************************/
/*            Swiper slider      */
/*===============================*/
.swiper-slider-hero {
  .swiper-container,
  .swiper-slide .slide-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .swiper-container {
    .swiper-button-prev,
    .swiper-button-next {
      background: transparent;
      width: 35px;
      height: 35px;
      line-height: 35px;
      margin-top: -30px;
      &:before {
        font-family: "Material Design Icons";
        font-weight: 900;
        color: $white;
      }
      &:hover {
        background: $primary;
        border-color: $primary !important;
      }
    }
    .swiper-button-prev {
      left: 10px;
      border: 1px solid rgba($white, 0.5);
      &:before {
        content: "\f0141";
      }
    }
    .swiper-button-next {
      right: 10px;
      border: 1px solid rgba($white, 0.5);
      &:before {
        content: "\f0142";
      }
    }
  }
  .swiper-pagination-bullet {
    color: $gray-100;
    background: transparent;
  }
  .swiper-pagination-bullet-active {
    color: $white;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 45px;
  }
  .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 13px;
  }
}

/*********************************/
/*           Kenburn Effect      */
/*===============================*/
.zoom-image {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.image-wrap {
  position: absolute;
  top: -200px;
  bottom: -200px;
  left: -100px;
  right: -100px;
  min-width: 100%;
  width: auto;
  min-height: 100%;
  height: auto;
  overflow: hidden;
  margin: auto;
  animation: 100s ppb_kenburns linear infinite alternate;
}

@keyframes move {
  0% {
    transform-origin: bottom;
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
  }
}

@keyframes ppb_kenburns {
  0% {
    transform: scale(1.3) translate(-10%, 10%);
  }
  25% {
    transform: scale(1) translate(0, 0);
  }
  50% {
    transform: scale(1.3) translate(10%, 10%);
  }
  75% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(1.3) translate(-10%, 10%);
  }
}
.carousel {
  &.carousel-slider {
    .control-arrow {
      &:hover {
        background-color: transparent !important;
      }
    }
  }
}
